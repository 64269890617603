<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline title="内容分发"></sb-headline>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
      <template #header="params">
        <div class="search_box">
          <el-row :gutter="10">
            <el-col :span="4">
              <div class="search_item">
                <div>渠道：</div>
                <el-select v-model="searchData.locationChannel" placeholder="请选择" clearable>
                  <el-option v-for="(item,index) in channelList" :key="index" :label="item.name" :value="item.value"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="search_item">
                <div>页面：</div>
                <el-select v-model="searchData.locationType" placeholder="请选择" clearable>
                  <el-option v-for="(item,index) in pageList" :key="index" :label="item.name" :value="item.value"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="search_item">
                <div>状态：</div>
                <el-select v-model="searchData.status" placeholder="请选择" clearable>
                  <el-option label="已启用" :value="1"></el-option>
                  <el-option label="已停用" :value="0"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="search_item">
                <div>内容形式：</div>
                <el-select v-model="searchData.contentType" placeholder="请选择" clearable>
                  <el-option label="图文" :value="1"></el-option>
                  <el-option label="H5链接" :value="2"></el-option>
                  <el-option label="视频" :value="3"></el-option>
                  <el-option label="页面" :value="4"></el-option>
                  <el-option label="微信小程序链接" :value="5"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="search_item">
                <div>坑位：</div>
                <el-select v-model="searchData.locationName" placeholder="请选择" clearable>
                  <el-option v-for="(item,index) in locationSelectList" :key="index" :label="item" :value="item"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="search_item">
                <div>子坑位：</div>
                <el-select v-model="searchData.locationSubName" placeholder="请选择" clearable>
                  <el-option v-for="(item,index) in subLocationSelectList" :key="index" :label="item" :value="item"></el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top: 20px;">
            <el-col :span="8">
              <div class="search_item">
                <div>所在地区：</div>
                <el-cascader @change="citySelectChange" v-model="citySelect" :props="props" style="width: 320px;"></el-cascader>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top: 10px;">
            <el-col :span="4" :offset="20">
              <el-button @click="pageHandler(params, 1, 'reset')">重置</el-button>
              <el-button @click="pageHandler(params, 1)" type="primary">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </template>
      <template #navBar>
        <div class="table_nav_bar">
          <router-link to="/management/content/contentDistribution/add">
            <el-button type="primary">新建分发</el-button>
          </router-link>
        </div>
      </template>
      <el-table-column label="渠道" fixed="left" prop="locationChannelName" :min-width="100"></el-table-column>
      <el-table-column label="坑位页面" prop="locationTypeName" :min-width="100"></el-table-column>
      <el-table-column label="坑位名称" prop="locationName" :min-width="120"></el-table-column>
      <el-table-column label="坑位子分类" prop="locationSubType" :min-width="120"></el-table-column>
      <el-table-column label="内容ID" prop="contentNo" :min-width="120"></el-table-column>
      <el-table-column label="内容类型" prop="contentTypeName" :min-width="120"></el-table-column>
      <el-table-column label="状态" prop="rule" :min-width="120">
        <template slot-scope="scope">
          <el-switch
            @change="(e)=>changeState(e,scope.row)"
            v-model="scope.row.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="图" prop="image" :min-width="180">
        <template slot-scope="scope">
          <img style="width: 100px;height: 100px" :src="scope.row.image" alt="无法加载">
        </template>
      </el-table-column>
      <el-table-column label="标题" prop="title" :min-width="120"></el-table-column>
      <el-table-column label="副标题" prop="subTitle" :min-width="120"></el-table-column>
      <el-table-column label="排序" prop="sort" :min-width="120"></el-table-column>
      <el-table-column label="地区" prop="crowdNo" :min-width="160">
        <template slot-scope="scope">
          <div v-for="(item,index) in scope.row.areaList" :key="index">{{ item.stateName+'/'+item.cityName+'/'+item.districtName }}</div>
        </template>
      </el-table-column>
      <el-table-column label="更新时间" prop="updateTime" :min-width="180"></el-table-column>
      <el-table-column label="点击PV" prop="clickPvCount" :min-width="120"></el-table-column>
      <el-table-column label="点击UV" prop="clickUvCount" :min-width="120"></el-table-column>
      <el-table-column label="点赞" prop="likeCount" :min-width="120"></el-table-column>
      <el-table-column label="转发" prop="forwardCount" :min-width="120"></el-table-column>
      <el-table-column label="最近操作人" prop="updateUserName" :min-width="120"></el-table-column>
      <el-table-column label="操作" fixed="right" prop="" width="180">
        <template slot-scope="scope">
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(1, scope.row)">编辑</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(2, scope.row)">上移</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(3, scope.row)">下移</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(4, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </my-table>
  </div>
</template>

<script>
/* eslint-disable */
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Table } from "@/utils/decorator";
import QRCode from "qrcodejs2";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
    data() {
    //这里存放数据
      return {
        listData: [],
        // 搜索数据
        searchData: {
          locationChannel: "",          //渠道
          locationType: "",             //页面
          locationName: '',             //坑位名称
          locationSubName: "",          //子坑位名称
          status: '',                   //状态
          contentType: '',              //内容形式
          stateCode: '',                //省
          cityCode: '',                 //市
          districtCode: '',             //区
        },
        listLoading: false,       //列表loading
        channelList: [],          //渠道列表
        pageList: [],             //页面列表
        citySelect: [],          //已选地区
        locationSelectList:[], //坑位列表
        subLocationSelectList:[], //
        props: {
          lazy: true,
          lazyLoad: (node, resolve) => {
            const { level,value } = node;
            this.$store.dispatch('base/getAreaList',{
              level: level+1,
              parentAreaCode: value
            }).then(res => {
              let nodes = res.data.list.map(item => {
                item.value = item.areaCode;
                item.label = item.areaName;
                item.leaf = level >= 2;
                return item
              })
              resolve(nodes);
            })
          }
        }
      };
    },
  filters: {
    stateFilter: function(val) {
      
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num, type) {
      if(type == 'reset'){
        this.resetSearchData();
      }
      this.getActivityList(params, num ? num : params.pageNum, params.pageSize);
    },
    // 地区选择
    citySelectChange(e){
      this.searchData.stateCode = e[0];                //省
      this.searchData.cityCode = e[1];                 //市
      this.searchData.districtCode = e[2];             //区
    },
    // 表格内操作
    tableHandle(type, row) {
      console.log("type:" + type + " row:" + JSON.stringify(row));
      switch (type) {
        case 1:     //编辑
          this.$router.push(`/management/content/contentDistribution/edit?distributeNo=${row.distributeNo}`);
          break;
        case 2:     //上移
          this.$store.dispatch('management/distributeSort',{
            distributeNo: row.distributeNo,
            sortType: 1
          }).then(res => {
            this.$message.success('操作成功')
            this.$refs.list.init();
          })
          break;
        case 3:     //下移
        this.$store.dispatch('management/distributeSort',{
            distributeNo: row.distributeNo,
            sortType: 2
          }).then(res => {
            this.$message.success('操作成功')
            this.$refs.list.init();
          })
          break;
        case 4:     //删除
          this.$confirm('是否删除此条分发?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$store.dispatch('management/distributeDelete',{
              distributeNo: row.distributeNo
            }).then(res => {
              this.$refs.list.init();
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
          break;
        default:
          break;
      }
    },
    // 跳转活动详情
    gotoDetail(row){
      this.$router.push(`/flowActivity/add?activityCode=${row.activityCode}`);
    },

    // 更改状态
    changeState(e,row){
      this.$store.dispatch('management/distributeStatus',{
        distributeNo: row.distributeNo,
        status: e,
      }).then(res => {
        this.$refs.list.init();
      })
    },

    // 重置搜索数据
    resetSearchData(e) {
      this.citySelect = [];
      this.searchData = {
        locationChannel: "",          //渠道
        locationType: "",             //页面
        locationName: '',             //坑位名称
        status: '',                   //状态
        contentType: '',              //内容形式
        stateCode: '',                //省
        cityCode: '',                 //市
        districtCode: '',             //区
      }
    },

    // 获取channel数据
    getChannelList(){
      this.$store.dispatch('management/getContentChannelList',{}).then(res => {
        this.channelList = res.data;
      })
    },
    // 获取坑位数据
    getLocationList(){
      this.$store.dispatch('management/getLocationList',{}).then(res => {
        this.locationSelectList = res.data.locationNameList
        this.subLocationSelectList = res.data.subLocationNameList
      })
    },
    // 获取页面数据
    getPageList(){
      this.$store.dispatch('management/getContentPageList',{}).then(res => {
        this.pageList = res.data;
      })
    },

    // 获取活动列表
    getActivityList(params,pageNum,pageSize) {
      this.listLoading = true;
      this.$store.dispatch('management/distributeList',{
        ...this.searchData,
        pageNum,
        pageSize,
      }).then(res => {
        if(res.code == 0){
          this.listData = res.data.list;
          params.render(res.data.total);
        }
        this.listLoading = false;
      })
    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // 获取channel数据
    this.getChannelList();
    // 加载坑位信息
    this.getLocationList();
    // 获取页面数据
    this.getPageList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box {
    padding: 0 20px;
    .search_box {
      background: #fff;
      padding: 20px;
      margin-top: 20px;
    }
    .table_nav_bar{
      width: 100%;
      background: #fff;
      padding: 20px 20px 0 20px;
      box-sizing: border-box;
      .el-button{
        margin-right: 10px;
      }
    }
    .table_row_rule{
      word-break: break-all; /** 换行，长单词会分成两行显示 **/
      text-overflow: ellipsis;/** 隐藏文本省略号 ... **/
      display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
      -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
      -webkit-line-clamp: 1; /** 显示的行数 **/
      overflow: hidden;  /** 隐藏超出的内容 **/;
    }
  }
  .handle_btn{
    padding: 0;
  }
  .channel_item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    >.el-input:nth-child(1){
      width: 260px;
    }
  }
  .channel_item:last-child{
    margin: 0;
  }
  .popularize_list_qrcode{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .qrcode_img_src{
    position: absolute;
    top: -10000%;
    z-index: -10000;
  }
  .search_item{
    display: flex;
    align-items: center;
    >div:first-child{
      flex-shrink: 0;
    }
  }
  .el-select{
    width: 100%;
  }
  ::v-deep .el-input{
    width: 100%;
  }
  ::v-deep .el-table__header thead tr th:first-of-type, .el-table__body tbody tr td:first-of-type{
    padding: 0 !important;
  }
  ::v-deep .el-table__body tbody tr td:first-of-type {
    padding-left: 0px !important;
  }
</style>
